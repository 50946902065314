import React from "react";
import styled, { keyframes } from "styled-components";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Container from "../components/Container";
import { Flex, Box } from "@rebass/grid";
import H1 from "../components/H1";
import H2 from "../components/H2";
import H3 from "../components/H3";
import H5 from "../components/H5";
import Lead from "../components/Lead";
import Paragraph from "../components/Paragraph";
import Card from "../components/Card";
import Button from "../components/Button";
import ExternalLink from "../components/ExternalLink";

import screenshot1 from "../../static/img/apps/1.jpg";
import screenshot2 from "../../static/img/apps/2.jpg";
import screenshot3 from "../../static/img/apps/3.jpg";
import screenshot4 from "../../static/img/apps/4.jpg";
import screenshot5 from "../../static/img/apps/5.jpg";
import screenshot6 from "../../static/img/apps/6.jpg";
import screenshot7 from "../../static/img/apps/7.jpg";
import screenshot8 from "../../static/img/apps/8.jpg";
import screenshot9 from "../../static/img/apps/9.jpg";
import screenshot10 from "../../static/img/apps/10.jpg";
import screenshot11 from "../../static/img/apps/11.jpg";

import sectionStudio from "../../static/img/section-studio.svg";
import sectionCollections from "../../static/img/section-collections.svg";
import sectionStatistics from "../../static/img/section-statistics.svg";
import sectionUsers from "../../static/img/section-users.svg";

import identity from "../../static/img/feature-icons/identity.svg";
import noCode from "../../static/img/feature-icons/no-code.svg";
import create from "../../static/img/feature-icons/create.svg";

import media from "../../static/img/feature-icons/media.svg";
import content from "../../static/img/feature-icons/content.svg";
import photos from "../../static/img/feature-icons/photos.svg";

import statistics from "../../static/img/feature-icons/statistics.svg";
import measure from "../../static/img/feature-icons/measure.svg";

import internal from "../../static/img/feature-icons/internal.svg";
import boss from "../../static/img/feature-icons/boss.svg";
import userContent from "../../static/img/feature-icons/user-content.svg";

const SectionHeader = styled(Box)`
  text-align: center;
`;

const SectionHeading = styled(H2)`
  margin-top: 1rem;
  margin-bottom: 0;
`;

const FeatureTitle = styled(H3)`
  margin-bottom: 0;
`;

const ModuleSlider = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #fafbff;
`;

const OverlayTop = styled.div`
  z-index: 2;
  height: 16px;
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(
    -180deg,
    rgba(159, 184, 198, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

const OverlayBottom = styled.div`
  z-index: 2;
  height: 16px;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(
    0deg,
    rgba(159, 184, 198, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

const rotate = keyframes`
  from {
    transform: translatez(0px);
  }

  to {
    transform: translateX(-4180px);
    /* transform: translateX(calc(-2 * (300px + 2rem) - 2rem)); */
  }
`;

const PhoneSlider = styled.div`
  width: 100%;
  display: flex;
  animation: ${rotate} 100s linear infinite;
`;

const PhoneContainer = styled.div`
  padding: 40px;
`;

const Phone = styled.img`
  width: 300px;
  height: 580px;
`;

const ComingSoon = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.primaryLight};
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 6px;
  font-size: 1rem;
`;

export const ProductPageTemplate = ({ title, description, modules }) => (
  <>
    <Helmet title={`Product | Appmantle`}>
      <meta
        name="description"
        content="Een complete oplossing voor het maken en beheren van jouw app."
      />
      <link rel="canonical" href="https://appmantle.com/product/" />
    </Helmet>

    <ModuleSlider>
      <Container>
        <Flex>
          <Box my={[4, 5, 6]}>
            <H1 as="h1">Dit is Appmantle</H1>
            <H3>
              Een complete oplossing voor het maken en beheren van jouw app.
            </H3>
          </Box>
        </Flex>
      </Container>
      <PhoneSlider>
        <PhoneContainer>
          <Phone src={screenshot1} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot2} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot3} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot4} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot5} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot6} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot7} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot8} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot9} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot10} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot11} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot1} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot2} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot3} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot4} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot5} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot6} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot7} />
        </PhoneContainer>
        <PhoneContainer>
          <Phone src={screenshot8} />
        </PhoneContainer>
      </PhoneSlider>
      <Container>
        <Flex pb={[5, 6, 6]} my={[3, 4, 4]}>
          <Box>
            <Flex mt={4} flexWrap="wrap">
              <Box pr={[3, 4, 4]} width={[1, 1 / 2, 1 / 2]}>
                <H3 as="h3">Gebruiksvriendelijk ontwerp</H3>
                <Paragraph>
                  De apps van Appmantle zijn gebruiksvriendelijk ontworpen en
                  voldoen aan de ontwerpstandaarden van Apple en Google.
                </Paragraph>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <H3 as="h3">Razendsnel</H3>
                <Paragraph>
                  Je hoeft niet onder te doen voor grote jongens. Met onze
                  moderne techniek is jouw app net zo snel als een app van Apple
                  of Google zelf.
                </Paragraph>
              </Box>
            </Flex>
            <Flex mt={4} flexWrap="wrap">
              <Box pr={[3, 4, 4]} width={[1, 1 / 2, 1 / 2]}>
                <H3 as="h3">Altijd up-to-date</H3>
                <Paragraph>
                  Je hoeft je geen zorgen te maken over de beveiliging of
                  updates; dat regelen wij allemaal voor je! Ook bij een nieuwe
                  Android of iOS-versie. Altijd up-to-date, altijd inclusief.
                </Paragraph>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <H3 as="h3">Via de bekende weg</H3>
                <Paragraph>
                  Na publicatie is jouw app te downloaden in de officiële Apple
                  App Store en Google Play Store. Zodra de app is geïnstalleerd,
                  staat deze prominent op de telefoon van jouw gebruiker of
                  klant.
                </Paragraph>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
      <OverlayTop />
      <OverlayBottom />
    </ModuleSlider>
    <Container>
      <Flex flexWrap="wrap" mx={-3} my={[4, 5, 5]}>
        <SectionHeader width={1} mx={[3, 6, 7]} mb={4}>
          <img src={sectionStudio} width="156px" />
          <br />
          <SectionHeading as="h2">Studio</SectionHeading>
          <Lead>
            Het maken en beheren van jouw app doe je in Studio. Hier klik en
            sleep je de verschillende modules op de pagina’s.
          </Lead>
        </SectionHeader>
        <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
          <img src={identity} width="40px" />
          <br />
          <FeatureTitle as="h3">Jouw eigen identiteit</FeatureTitle>
          <Paragraph>
            Kies een stijl en kleuren die bij jou passen. Of het nu gaat voor
            standaard, klassiek of een speels uiterlijk.
          </Paragraph>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
          <img src={noCode} width="40px" />
          <br />
          <FeatureTitle as="h3">
            Makkelijk & zonder te programmeren
          </FeatureTitle>
          <Paragraph>
            Door te klikken en te slepen stel je super eenvoudig jouw app samen.
          </Paragraph>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
          <img src={create} width="40px" />
          <br />
          <FeatureTitle as="h3">Bedenk & vernieuw</FeatureTitle>
          <Paragraph>
            Voeg op elk gewenst moment een nieuwe module toe of pas de volgorde
            van de modules op de pagina aan.
          </Paragraph>
        </Box>
      </Flex>
    </Container>
    <ModuleSlider>
      <Container>
        <Flex flexWrap="wrap" mx={-3} my={[5, 6, 6]}>
          <SectionHeader width={1} mx={[3, 6, 7]} mb={4}>
            <img src={sectionCollections} width="156px" />
            <br />
            <SectionHeading as="h2">Verzamelingen</SectionHeading>
            <Lead>
              Content voor lijsten, artikelen, locaties en evenementen maak je
              bij Appmantle in verzamelingen.
            </Lead>
          </SectionHeader>
          <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
            <img src={media} width="40px" />
            <br />
            <FeatureTitle as="h3">
              Teksten en afbeeldingen, alles bij elkaar
            </FeatureTitle>
            <Paragraph>
              Beheer lijsten, artikelen, locaties, evenementen en meer
              overzichtelijk met content beheer.
            </Paragraph>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
            <img src={content} width="40px" />
            <br />
            <FeatureTitle as="h3">Zit je mij nu te versieren?</FeatureTitle>
            <Paragraph>
              Dankzij de gebruiksvriendelijke tekstverwerker bepaal je zelf hoe
              je jouw teksten opmaakt.
            </Paragraph>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
            <img src={photos} width="40px" />
            <br />
            <FeatureTitle as="h3">
              Professionele foto’s tot je beschikking
            </FeatureTitle>
            <Paragraph>
              Met de Unsplash integratie heb je direct keuze uit meer dan 1
              miljoen foto’s.
            </Paragraph>
          </Box>
        </Flex>
      </Container>
      <OverlayTop />
      <OverlayBottom />
    </ModuleSlider>

    <Container>
      <Flex flexWrap="wrap" mx={-3} my={[5, 6, 6]}>
        <SectionHeader width={1} mx={[3, 6, 7]} mb={4}>
          <img src={sectionStatistics} width="156px" />
          <br />
          <SectionHeading as="h2">Statistieken</SectionHeading>
          <Lead>
            Niets is zo waardevol als inzicht in het gebruik van jouw app. Je
            komt het te weten met de statistieken.
          </Lead>
        </SectionHeader>
        <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
          <img src={statistics} width="40px" />
          <br />
          <FeatureTitle as="h3">
            Inzicht in het gebruik van jouw app
          </FeatureTitle>
          <Paragraph>
            Real-time inzicht in het totaal aantal bezoeken, het uniek aantal
            bezoekers en de verdeling tussen Android en iPhone van je app.
          </Paragraph>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
          <img src={measure} width="40px" />
          <br />
          <FeatureTitle as="h3">Meten is weten</FeatureTitle>
          <Paragraph>
            Bekijk per artikel, locatie en evenement hoe het scoort onder jouw
            doelgroep.
          </Paragraph>
        </Box>
      </Flex>
    </Container>
    <ModuleSlider>
      <Container>
        <Flex flexWrap="wrap" mx={-3} my={[5, 6, 6]} mb={5}>
          <SectionHeader width={1} mx={[3, 6, 7]} mb={4}>
            <img src={sectionUsers} width="156px" />
            <div>
              <ComingSoon>Binnenkort beschikbaar</ComingSoon>
            </div>
            <SectionHeading as="h2">Gebruikersbeheer</SectionHeading>
            <Lead>
              Ideaal voor interne apps voor bedrijven of verenigingen.
            </Lead>
          </SectionHeader>
          <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
            <img src={internal} width="40px" />
            <br />
            <FeatureTitle as="h3">
              Psst, zal ik je een geheimpje vertellen?
            </FeatureTitle>
            <Paragraph>
              Scherm onderdelen binnen jouw app af voor alleen geregistreerde of
              goedgekeurde gebruikers. Ideaal voor interne apps voor bedrijven
              of verenigingen.
            </Paragraph>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
            <img src={boss} width="40px" />
            <br />
            <FeatureTitle as="h3">Jij blijft de baas</FeatureTitle>
            <Paragraph>
              Met het gebruiksvriendelijke gebruikersbeheersysteem zie je direct
              wie wel en geen toegang heeft tot jouw app.
            </Paragraph>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]} px={3} my={3}>
            <img src={userContent} width="40px" />
            <br />
            <FeatureTitle as="h3">
              Laat de app-gebruikers participeren!
            </FeatureTitle>
            <Paragraph>
              Met gebruikersbeheer wordt het ook mogelijk om jouw gebruikers
              zelf content te laten creëren. Als beheerder kun je altijd bepalen
              welke artikelen je toelaat in je app.
            </Paragraph>
          </Box>
        </Flex>
      </Container>
      <OverlayTop />
      <OverlayBottom />
    </ModuleSlider>
    <Container>
      <Box mt={[5, 5, 6]}>
        <H2 as="h2">Onze modules</H2>
        <Lead>
          De pagina’s van jouw app zijn te vullen met modules. We hebben er al
          een hoop en er komen alleen maar meer bij!
        </Lead>
      </Box>
      <Flex flexWrap="wrap" mx={-3} mt={5}>
        {modules.map(module => (
          <Box width={[1, 1 / 2, 1 / 2]} px={3} mb={5}>
            <H3>
              {module.title}{" "}
              {module.comingSoon && (
                <ComingSoon>Binnenkort beschikbaar</ComingSoon>
              )}
            </H3>
            <Paragraph>{module.description}</Paragraph>
          </Box>
        ))}
      </Flex>
    </Container>
  </>
);

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProductPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        modules={frontmatter.modules}
      />
    </Layout>
  );
};

export default ProductPage;

export const ProductPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        modules {
          title
          description
          comingSoon
        }
      }
    }
  }
`;
